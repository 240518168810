import React from 'react'
import { withPrefix } from 'gatsby'
import siteConfig from '../../../data/siteConfig'
import styled from 'styled-components'

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  font-family: sans-serif;
`
const TitleContainer = styled.div`
  vertical-align: top;
  text-align: center;
  width: 100%;
  height: 50px;
`
const TitleContainer2 = styled.div`
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 50px;
`
const TitleContainer3 = styled.div`
  vertical-align: bottom;
  text-align: center;
  width: 100%
  height: 50px;;
`

const HeroTitle = styled.h1`
  font-weight: 700;
  font-size: 3rem;
  color: #fff;
  position: fixed;
`

const Hero = ({ className, title1, title2, title3 }) => (
  <HeroContainer className={className}>
    <TitleContainer>
      <HeroTitle>{title1}</HeroTitle>
    </TitleContainer>
    <TitleContainer2>
      <HeroTitle>{title2}</HeroTitle>
    </TitleContainer2>
    <TitleContainer3>
      <HeroTitle>{title3}</HeroTitle>
    </TitleContainer3>
  </HeroContainer>
)

export default styled(Hero)`
  ${p =>
    `background-image: url(${p.heroImg || withPrefix(siteConfig.siteCover)});`}
  height: 105vh;
  background-attachment: fixed;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
`
