module.exports = {
  siteTitle: 'Zach Lee',
  headline1: 'I\'M ZACH.',
  headline2: 'WITH AN H.',
  headline3: 'FROM THE A.',
  minIntro: 'Zach Lee, Software Engineer II',
  nikeTag: '@Nike.inc',
  siteDescription: `Personal site for Zach Lee.`,
  keywords: ['zach', 'lee', 'zach lee', 'Zach', 'Lee', 'Zach Lee', 'zachary', 'Zachary', 'Nike', '360Works'],
  authorName: 'Zach Lee',
  twitterUsername: '@zatcharylee',
  githubUsername: 'zachlee',
  authorAvatar: 'src/assets/profile.jpg',
  authorDescription: `I'm just a simple man trying to make my way in the universe. My first experience with web development came in 2005, when I became completely obsessed with customizing my Myspace page. I thought I had found my calling in programming when I wrote my first script in Assembly and it worked, but I realized my love for breaking down complex challenges into simple solutions when I was shown a more effective way to implement the algorithm.`,
  authorDescription2: ' As a back-end engineer and dev-ops professional, I\'m dedicated to writing code and managing infrastructure for scalable microservices and serverless applications. I view myself as a competition enthusiast and I live by three key philosophies: always strive for improvement, learn from everyone you meet, and remember that only a Sith deals in absolutes.',
  authorDescription3: '&#9744; Use Tab Character',
  linkToFullAbout: '',
  icon: 'src/assets/favicon.ico',
  skills: [
    {
      name: 'Java',
      level: 100
    },
    {
      name: 'AWS Cloud',
      level: 100
    },
    {
      name: 'NoSQL Database',
      level: 80
    },
    {
      name: 'Javascript',
      level: 72
    },
    {
      name: 'SQL Database',
      level: 60
    },
    {
      name: 'React',
      level: 50
    },
    {
      name: 'Vue.js',
      level: 60
    },
    {
      name: 'Dancing',
      level: 0
    },
    /* more skills here */
  ],
  jobs: [
    /* more jobs here */
    {
      company: "Nike",
      begin: {
        month: 'mar',
        year: '2019'
      },
      duration: 'current',
      occupation: "Backend Software Engineer",
      description: "I am part of the Social team, responsible for design, implementation, and management the social and moderation platforms for Nike's NTC and NRC app experiences."
    },  {
      company: "360Works",
      begin: {
        month: 'jul',
        year: '2017'
      },
      duration: '2 yr',
      occupation: "Full Stack Software Developer",
      description: "Consultant responsible for working with various companies to design and implement business logic into technical solutions."

    }, {
      company: "Canal",
      begin: {
        month: 'dec',
        year: '2016'
      },
      duration: '6 mos',
      occupation: "Backend Developer",
      description: "Part time backend developer for platform used to managed students' workouts for class and team sports."
    }
  ],
  social: {
    // twitter: "https://twitter.com/zatcharylee",
    nikeTwitter: "https://twitter.com/nike",
    linkedin: "https://www.linkedin.com/in/leezacha",
    // github: "https://github.com/zachlee",
    // email: "zachlee@live.com"
  },
  siteUrl: 'http://zachlee.io',
  pathPrefix: '/', // Note: it must *not* have a trailing slash.
  siteCover: 'src/assets/active.jpg',
  googleAnalyticsId: 'UA-138683536-1',
  background_color: '#ffffff',
  theme_color: '#25303B',
  fontColor: "#000000cc",
  enableDarkmode: false, // If true, enables dark mode switch
  display: 'minimal-ui',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    }
  ],
  projects: [
      {
          name: "Nike Activity and Innovation",
          description: "Developing and supporting Social, Moderation, and Retail platform applications.",
          technologies: "Java, Javasript, Cloud Infrastructure"
      },
      {
          name: "Studentrade",
          description: "Pet project web application to increase connectivity between college students on the same campus that are buying and selling textbooks.",
          technologies: "SpringBoot, Vue.js, AWS",
      },
      {
          name: "Scoreboard Nation",
          description: "Lightweight, open source scoreboard application built to work with the ESPN's 'Secret' API. For tracking specific games that you choose.",
          technologies: "Vue.js",
          html_url: "http://suwaneenation.com"
      },
      {
          name: "Operations Dashboard",
          description: "Implemented workflows company to further develop a central Filemaker database solution that was used for inventory, POS, location transfer between 10 locations.",
          technologies: "Filemaker SQL Database and User Interface"
      },
      {
          name: "Consultant Matching Service Application",
          description: "Created a data driven application to create engine for matching qualified consultants with suited demands. Leveraged NoSQL managed cloudsearch integrated with in house technology to provide highly relevant results.",
          technologies: "Java, Vue.js, Cloud Infrastructure"
      }
  ]
}